.contacts-content {
  .contacts_info_content {
    border-left: 1px solid $lightgrey;
    margin-left: 2%;
    padding-left: 5px;
    padding-top: 5px;
    flex-direction: column;
    gap: 20px;


    .info {
      flex-direction: column;
      gap: 5px;

      strong{
        font-weight: bold;
      }

      h2{
        color: $grey;
      }
      p{
        color: $text;
      }
    }

    div{
      iframe{
        width: 100%;
      }
    }
  }

}