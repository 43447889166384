* {
  box-sizing: border-box;
  @include base-font;
}

body {
  @include base-font;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.wrapper {
  box-sizing: border-box;
  position: relative;
  max-width: 1360px;
  min-width: 270px;
  margin: 0 auto;
  padding: 0 $gutter;
  min-height: 100vh;

  //grid options
  grid-template-rows: $header_height 1fr $footer_height;
  grid-row-gap: $gutter;
}

button {
  border: 1px solid $accent;
  border-radius: 6px;
  color: $accent;
  background: $background;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 1;
  }
}

select {
  border: 1px solid $accent;
  border-radius: 6px;
  outline-color: $accent_darker;
}

.h1 {
  @include font-size-36;
}

.h2 {
  @include font-size-32;
}

.h3 {
  @include font-size-24;
}

.h4 {
  @include font-size-18;
}

.p {
  @include font-size-15;
}

.span {
  @include font-size-13;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $darkgrey; /* Black background with opacity */
  z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
}

/* Убираем стандартный маркер Chrome */
.overlay-details summary::-webkit-details-marker {
  display: none
}

/* Убираем стандартный маркер Firefox */
.overlay-details > summary {
  list-style: none;
}

.success {
  margin-top: 10px;
  font-size: 14px;
}

.error {
  margin-top: 10px;
  font-size: 14px;

  .item_error {
    color: red;
  }
}

table {
  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}

.details-block {
  transition: slideDown 3s ease-in;
  position: relative;
  overflow: hidden;

  summary {
    list-style: none;
    margin-left: 10px;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }


    &:before {
      content: '';
      border-width: .5rem;
      border-style: solid;
      border-color: transparent transparent transparent $text;
      position: absolute;
      left: .3rem;
      top: .13rem;
      transform: rotate(0);
      transform-origin: .2rem 50%;
      transition: .25s transform ease;
    }
  }

  &[open] {
    summary:before {
      top: 0;
      transform: rotate(90deg);
    }
  }

  &[open] summary ~ * {
    animation: slideDown .5s ease-in-out;
  }
}


.page {
  grid-template-rows: auto 1fr;
  grid-row-gap: $row_gutter;

  h1 {
    color: $grey;
  }

  .page_content_flex {
    display: flex;
    flex-direction: column;
    gap: $row_gutter;
  }

  .page_content {
    strong {
      font-weight: bold;
    }

    h2 {
      color: $grey;
    }

    p {
      color: $text;
    }
  }
}

.input-privacy-div {
  label > a {
    color: $accent;
    text-decoration-color: $accent_darker;
    text-underline-offset: 3px;
  }
}


button[disabled] {
  opacity: 0.3;
}

.image-slider {
  position: relative;
  $button_width: 50px;
  justify-content: center;
  .current{
    width: inherit;
    cursor: pointer;
    -webkit-user-drag: none;
    user-select: none;
  }
  .previous, .next{
    position: absolute;
    z-index: 10;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    color: transparent;
    font-size: 36px;
    user-select: none;
    width: $button_width;
    &:hover{
      background: $lightgrey;
      color: $grey;
      opacity: 0.5;
      visibility: visible;
    }
  }
  .previous{
    left: 0;
  }
  .next{
    top: 0;
    right: 0;
  }
}