@use "sass:math";
.search_page {
  grid-template-rows: 40px 30px 1fr;
  grid-row-gap: 14px;

  h1{
    color: $grey;
    border-bottom: 1px solid $lightgrey;
    width: 100%;
    max-width: 1080px;
    justify-self: center;
  }

  .list_products {
    max-width: 1080px;
    justify-self: center;
    grid-template-rows: repeat(auto-fill, 203px);
    grid-row-gap: 30px;
    .item_products {
      grid-column-gap: 20px;
      min-height: 203px;
      align-items: unset;
      .photo_item {
        width: 100%;
        height: 100%;
      }

      .description_item {
        height: unset !important;
        grid-template-rows: repeat(4, 1fr);
        padding: 20px 0;

        .head_description {
          @include font-size-18;
        }

        .particle_description{
          grid-row: 2/4;
          @include font-size-15;
          -webkit-line-clamp: 4;
        }

        .btn_description{
          //border-bottom: 1px solid $accent;
          @include font-size-15;
          align-self: center;
          padding: 0;

        }
      }
      .info_item{
        padding: 20px 0;
        height: unset;
        grid-template-rows: repeat(4, 1fr);
        white-space: nowrap;
        .available_info{
          div{
            @include font-size-18;
            img{
              margin-right: 7px;
              width: 12px;
            }
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.055em;

            color: #000000;
          }
        }

        .coast_info{
          @include font-size-18;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.055em;

          color: rgba(21, 37, 54, 0.5);

          span{
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: -0.055em;
          }
        }

        .counter_info{
          @include font-size-18;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.055em;

          color: rgba(21, 37, 54, 0.5);
        }

        .buy_info{
          @include font-size-15;
          height: unset;
        }
      }

    }
  }
}