.home-content {
  grid-gap: $gutter;
}

.main-panel-home {
  min-height: 586px;

  //grid options
  grid-template-columns: 8.22fr 7.82fr;

  .info_panel {
    background: #EAEAEA;
    border-radius: 5px 0 0 5px;
    //grid options
    align-items: center;

    .block_info_panel {
      //grid options
      align-content: space-between;
      grid-row-gap: 30px;
      min-height: 45%;
      max-width: 61%;
      margin-left: 16.5%;

      .h1 {
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        /* or 167% */

        letter-spacing: -0.015em;

        color: $black;
      }

      .p {
        //margin-top: 18px;
        //margin-bottom: 27px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        /* or 133% */

        letter-spacing: -0.015em;

        color: $black;
      }

      button {
        font-size: 17px;
        align-self: end;
        width: 192px;
        height: 53px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        /* or 133% */

        letter-spacing: -0.015em;

        color: $background;

        background: $accent;
        border: 0;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }

  .img_panel {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: saturate(0);
    border-radius: 0 14px 14px 0;
  }
}

.services-content {
  //min-height: 820px;
  //width: 83.07%;
  margin: 0 8.46%;
  padding: 64px 0;
  grid-template-rows: auto 1fr;

  .head-48-services {
    width: 69%;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */

    letter-spacing: -0.015em;

    color: $black;
  }

  .list-services {
    min-height: 100%;
    padding-top: 64px;
    //height: inherit;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $gutter;
    grid-row-gap: 56px;

    .item {
      display: grid;
      grid-template-rows: auto 1fr 40px;
      grid-row-gap: 24px;

      .h1-item {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 36px;
        /* identical to box height, or 180% */

        letter-spacing: -0.015em;

        color: $black;
      }

      .p-item {
        @include font-size-18;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* or 144% */

        letter-spacing: -0.015em;

        color: $black;
        opacity: 0.8;
      }

      a {
        justify-content: center;
        align-items: center;
        -webkit-appearance: button;
        -moz-appearance: button;
        text-decoration: none;
        @include font-size-15;
        width: 151px;
        height: 40px;
        background: $accent;
        border-radius: 6px;
        border: 0;

        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.015em;
        color: $background;
        cursor: pointer;
      }
    }
  }
}

.news-content {
  margin: 0 8.46%;

  gap: 5px;

  h2 {
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
  }

  hr {
    opacity: 0.2;
  }

  .post_news {
    flex-direction: column;
    gap: 1rem;
    padding: 0;

    h3 {
      font-size: 20px;
      line-height: 36px;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      color: $black;
      opacity: 0.8;
      font-weight: 400;
      max-width: 50%;
      line-height: 26px;
    }

    sub {
      color: $grey;
      font-size: 18px;
    }

    a {
      color: $accent;
      text-underline-offset: 3px;
    }

  }

  .btn_change_post {
    max-width: calc(50% - 30px);
    align-items: center;
    margin: 1rem 0 0;
    gap: 1rem;

    button {
      width: 50%;
      font-size: 15px;
    }
  }

  .a_show_all_news {
    height: 40px;

    a {
      align-items: center;
      justify-content: center;
      width: 151px;
      height: 40px !important;
      text-decoration: none;
      border-radius: 6px;
      color: $background;
      font-size: 15px;
      background: $accent;
    }
  }
}

.form-question-content {
  padding: 27px 8.46% 26px;
  grid-template-columns: 48.6% 192px 192px;
  grid-template-rows: 54px 108px auto;
  grid-column-gap: $gutter;
  grid-row-gap: 14px;
  width: 100%;
  min-width: 900px;
  min-height: 283px;
  background: $darker_white;

  .form-question-title {
    @include font-size-36;
    grid-row: 1 / -1;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    align-self: center;
    letter-spacing: -0.015em;
  }

  #form-question-textarea-div {
    grid-column: 2 / -1;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      border: 1px solid rgba(0, 0, 0, 0.25);
      min-width: 414px;
    }
  }

  button {
    @include font-size-15;
    width: 78.6%;
    height: 40px;
  }

  .flex {
    flex-direction: column;
    gap: 9px;

    label {
      white-space: nowrap;
      color: $grey;
      font-weight: bold;
    }

    input {
      border: 1px solid rgba(0, 0, 0, 0.25);
      min-width: 192px;
      padding-left: 5px;
    }

    .smart-captcha {
      height: 0;
      width: 0;
    }

    .error-empty {
      position: absolute;
      height: 0;
      width: 0;
    }
  }

  .input-privacy-div {
    grid-column: 2 / 4;
  }
}