/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1040px) and (min-width: 730px) {

  .products_catalog {
    grid-template-rows: auto auto 1fr !important;
    .manipulation_products {
      width: 301px;
      grid-column: 1 / -1;
      height: fit-content;
    }
  }
  .products_products {
    .item_products {
      align-items: start !important;
      grid-template-columns: 137px repeat(2, 1fr) !important;
      grid-row-gap: 15px;
      .description_item {
        height: inherit !important;
        grid-row: 1 / 3;
        overflow-y: hidden;
        grid-row-gap: 5px;

        .head_description {
          -webkit-line-clamp: 4 !important;
        }

        .particle_description {
          -webkit-line-clamp: 8 !important;
        }

        .btn_description {
          padding-top: 0 !important;
        }
      }

      .info_item {
        align-self: end;
      }
    }
  }



}

/**/
//
//
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .main-header {
    grid-template-columns: repeat(5, 1fr) minmax(0, 5.11fr);

    .catalog-header, .home-header, .contacts-header, .about-header {
      @include font-size-15;
    }

    .logo-header {
      @include font-size-24;
    }

    .catalog-header {
      img {
        height: 100%;
        width: calc(13px * 0.78);
        align-self: center;
      }

      .modal_catalog {
        .search_modal {
          display: inherit;
        }
      }
    }


    .search-header {
      display: none;

      border-color: rgba(0, 0, 0, 0);
      padding: 0;

      input {
        display: none;
      }

      img {
        display: none;
        width: calc(15px * 0.9);
      }
    }

    .question-header {
      button {
        @include font-size-13;
        height: calc(40px * 0.78 + 3px);
        width: calc(145px * 0.78 + 5px);
      }
    }
  }


  .news-content{
    .post_news{
      p{
        max-width: 100%;
      }
    }
  }

  .product_page{
    width: initial !important;
    min-width: 70%;
  }

  .form-question-content{
    grid-template-columns: 192px 1fr !important;
    grid-template-rows: 69px 54px 108px auto;
    min-width: initial;

    .form-question-title{
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    input{
      width: 192px;
    }

    #form-question-textarea-div {
      grid-column: 1 / -1;

      textarea{
        max-width: 414px;
      }
    }
    .input-privacy-div{
      grid-column: 1/ -1;
      input{
        width: inherit;
      }
    }
  }}

/**/
//
//
/* Small Devices, Tablets */
@media only screen and (max-width: 730px) {
  $gutter: 25px;
  .wrapper {
    padding: 0 0 !important;
  }
  .main-header {
    padding: 0 $gutter !important;
    height: $header_height !important;
    grid-gap: 15px;

    .question-header {
      display: none;
    }
  }
  .catalog-content {
    padding: 0 $gutter !important;
  }
  .catalog {
    display: block;

    .navigation_catalog {
      display: none;
    }

    .products_catalog {
      grid-gap: $gutter $gutter;
      grid-column: 1 / -1;

      .products_products {
        grid-row-gap: $gutter;
      }
    }
  }

  .breadcrumb {
    position: static;
    gap: 3px;
    font-size: 12px;
    padding-left: 0;
    height: 25px;
  }
  .footer {
    div {
      margin: 0 $gutter;
    }
  }

  .page{
    padding: 0 $gutter;
  }

  .product_page{
    .product{

      .title_description{
        .title{
          @include font-size-24;
        }

        .description{
          @include font-size-15;
        }
      }
    }
  }

  .service-page{
    $padding: 30px;
    h1{
      padding: 0 $padding;
    }
    h2{
      padding: 0 $padding;
    }
    p{
      padding: 0 $padding;
    }
    .service-information{
      padding: 0 $padding;
    }
  }
}

/**/
//


///* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  $gutter: 25px;

  .main-header {
    padding: 0 $gutter !important;
    grid-template-columns: 27px 100px;
    background-color: $darkgrey;


    .logo-header {
      order: 0;
    }

    .catalog-header {
      order: -1;

      img {
        width: 27px;
        height: 27px;
      }

      .text_catalog {
        display: none;
      }
      .modal_catalog{
        display: none;
      }
      &:hover {
        opacity: 1;

        .modal_catalog {
          visibility: hidden;
        }

        #overlay{
          display: block;
        }
      }
    }

    .home-header {
      display: none;
    }

    .contacts-header {
      display: none;
    }

    .about-header {
      display: none;
    }

  }
  .catalog-content {
    padding: 0 $gutter !important;

    .catalog {
      .products_catalog {
        .products_products {
          .item_products {
            grid-template-columns: 1fr !important;
            grid-template-rows: repeat(5, 1fr);
            grid-row-gap: 15px;

            .photo_item {
              grid-row: 1/4;
              height: 100%;
              width: auto;
              background-position: center;
            }

            .description_item {
              grid-column: 1 / 2;
              grid-row-gap: 6px;
              height: 78px;

              .btn_description {
                @include font-size-15;
                padding: 0;

              }
            }

            .info_item {
              grid-template-rows: 1fr 2fr !important;
              grid-template-columns: 1fr 1fr 1fr;
              height: 88px;

              .buy_info {
                grid-column: 1 / -1;
                @include font-size-15;
                height: 47px;
              }
            }

          }
        }
      }
    }
  }

  .home-content {
    grid-gap: $gutter 0;

    .main-panel-home {
      grid-template-columns: 1fr;
      min-height: auto;
      .info_panel {
        background: $background;
        .block_info_panel {
          max-width: 100% !important;
          margin: 0 8.46%;
          min-height: auto !important;

          .h1 {
            font-size: 24px;
            line-height: 35px;
          }
          button{
            width: 100%;
          }
        }
      }

      .img_panel {
        display: none;
      }
    }


    .services-content {
      padding: 0;
      .head-48-services {
        font-size: 24px;
        width: 100%;
        line-height: 35px;
      }

      .list-services {
        padding: 20px 0 0;
        grid-row-gap: 20px;
        grid-template-columns: 1fr !important;
        grid-template-rows: repeat(auto-fit, 1fr);

        .item {
          grid-row-gap: 11px;
          .h1-item {
            font-size: 16px !important;
          }

          .p-item {
            font-size: 15px !important;
          }
          button{
            width: 100%;
          }
        }
      }
    }
  }


  .news-content{
    margin-top: 10px;
    h2{
      font-size: 24px;
    }

    .post_news{
      gap: 0.5rem;
      h3{
        font-size: 16px;
      }
      p{
        font-size: 15px;
      }
      sub{
        font-size: 15px;
      }
    }
    .btn_change_post{
      button{
        width: 100%;
      }
    }
  }

  .product_page{
    .breadcrumb{
      display: none;
    }
    .product{
      grid-template-columns: max(303px) !important;
      grid-row-gap: $gutter;
      justify-content: center;
      .title_description{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
    }
  }
  .footer {
    div {
      margin: 0 $gutter;
    }
  }

  .search_page{
    .list_products{
      grid-template-rows: unset;
      align-items: start;
      .item_products{
        grid-template-columns: 1fr !important;
        grid-template-rows: 2fr repeat(2, 1fr);
        grid-row-gap: unset;

        .description_item{
          grid-column: unset;
          padding: 10px 20px;
        }

        .info_item{
          padding: 10px 20px;
          $font-size: 18px;
          .available_info{
            div{
              font-size: $font-size !important;
            }
          }
          .coast_info{
            font-size: $font-size !important;
            span{
              font-size: $font-size !important;
            }
          }
          .counter_info{
            font-size: $font-size !important;
            span{
              font-size: $font-size !important;
            }
          }
        }
      }
    }
  }

  .form-question-content{
    grid-template-columns: 1fr !important;
    grid-template-rows: 69px 54px 54px 108px 40px auto;

    .form-question-title{
      @include font-size-24;
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    input{
      width: 192px;
    }

    #form-question-textarea-div {
      grid-column: initial !important;

      textarea{
        min-width: initial !important;
        width: 100%;
      }
    }
    button{
      width: 100%;
    }
  }

  .service-page{
    .service-information{
      details{
        table{
          font-size: 10px;
        }
      }
    }
  }
}

///**/
//
//
/* Custom, iPhone Retina */
@media only screen and (max-width: 375px) {

  .breadcrumb {
    div {
      font-size: 11px !important;
    }
  }
  .catalog-content {
    //padding: 0 !important;
  }

  .products_catalog {
    grid-column-gap: 0 !important;

    .manipulation_products {
      .head_manipulation {
        font-size: 20px !important;
      }

      .manipulations_manipulation {
        grid-template-columns: repeat(auto-fill, 120px) !important;

        div {
          font-size: 13px !important;
        }
      }

    }

    .item_products {
      .description_item {
        .head_description {
          font-size: 13px !important;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .particle_description {
          font-size: 11px !important;
        }
      }
    }
  }

  .info_item {
    div {
      font-size: 11px !important;

      span {
        font-size: 13px !important;
      }
    }
  }


  .footer {
    .info-footer {
      max-width: 100% !important;

      div {
        font-size: 10px;
      }
    }
  }

  .service-page{
    .service-information{
      details{
        summary{
          @include font-size-13;
        }
        table{
          font-size: 9px;
        }
      }
    }
  }
}

/**/
//
//
///*==========  Mobile First  ==========*/
//
///* Custom, iPhone Retina */
//@media only screen and (min-width : 320px)
///**/
//
//
///* Extra Small Devices, Phones */
//@media only screen and (min-width : 480px)
///**/
//
//
///* Small Devices, Tablets */
//@media only screen and (min-width : 768px)
///**/
//
//
///* Medium Devices, Desktops */
//@media only screen and (min-width : 992px)
///**/
//
//
///* Large Devices, Wide Screens */
//@media only screen and (min-width : 1200px)
///**/