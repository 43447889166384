@use "sass:math";
.main-header {
  .catalog-header {
    .modal_catalog {
      z-index: 101;
      top: math.div($header_height, 2) + 12px;
      min-width: 238px;
      //height: 264px;
      //background-image: url("../img/svg/catalog_modal.svg");
      background: $background;
      border: 2px solid $lightgrey;
      border-radius: 4px;
      position: absolute;
      transform: translateX(-25px);
      opacity: 0;
      transition: opacity 0.5s ease;
      visibility: hidden;
      padding: 14px 16px 14px;

      grid-template-rows: repeat(auto-fit, 27px);
      grid-row-gap: 14px;

      .head_modal {
        @include font-size-15;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: -0.015em;

        /* navbar page */

        color: $text;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        cursor: auto;

        &:hover {
          opacity: 1;
        }
      }

      .search_modal {
        display: none;
        form {

          height: 27px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          grid-template-columns: 1fr 16px 12px;
          align-content: center;

          input {
            @include font-size-13;

            padding: 3px 0 0;
            border: 0;

            &::placeholder {
              @include base-font;
              @include font-size-13;
              color: $grey;
            }

            &:focus {
              outline: none;
            }
          }

          img {
            margin-left: 2px;
            width: 15px;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }

            &:active {
              opacity: 1;
            }
          }
        }
      }

      .item_modal {
        @include font-size-13;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: $text;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 1px;
        min-height: 27px;

        grid-template-columns: 1fr 14px 12px;
        align-items: center;
        cursor: pointer;


        .pointer {
          margin-top: 2px;
          height: 14px;
          background-image: url("../img/svg/pointer.svg");
          background-repeat: no-repeat;

          &:hover {
            background-image: url("../img/svg/pointer_accent.svg");
          }
        }

        &:active {
          opacity: 1;
          color: $grey;


          .pointer {
            background-image: url("../img/svg/pointer_accent.svg");
          }
        }

        &:hover {
          opacity: 0.8;
        }


      }
    }

    #overlay {
      $gutter: 25px;
      overflow-y: scroll;
      &:hover {
        opacity: 1;
      }

      .header_overlay {
        padding: 0 $gutter;
        height: 80px;
        gap: $gutter;
        border-bottom: 1px solid #C9C9C9;

        div {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          align-self: center;
          letter-spacing: -0.015em;

          /* grey invis 50% */

          color: #7E7E7E;

        }
      }

      .navigation_overlay {

        padding: 20px $gutter;
        grid-gap: 20px;

        .search_overlay {
          grid-template-columns: 15px 1fr;
          grid-column-gap: 6px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          padding: 0 6px;
          height: 40px;

          img {
            width: 15px;
            height: 100%;
            padding: 3px 0 0;
            align-self: center;
          }

          input {
            @include font-size-15;
            background-color: rgba(0, 0, 0, 0);
            padding: 3px 0 0;
            border: 0;

            &::placeholder {
              @include base-font;
              @include font-size-15;
              color: $grey;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .page_overlay{
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.015em;

          color: #000000;
        }

        .catalog_overlay {
          .head_catalog_overlay {
            list-style: none;
            &::marker{
              display: none;
            }
          }

          .item_catalog_overlay {
            @include font-size-13;
            height: 27px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.015em;
            color: $text;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            padding-left: 9px;

            grid-template-columns: 1fr 18px 18px;
            align-items: center;

            margin-top: 14px;

            .pointer {
              margin-top: 2px;
              height: 14px;
              width: 18px;
              background-image: url("../img/svg/pointer-extend.svg");
              background-repeat: no-repeat;
              background-position: center;

              &:hover {
                background-image: url("../img/svg/pointer-extend_accent.svg");
              }
            }

            &:active {
              opacity: 1;
              color: $grey;


              .pointer {
                background-image: url("../img/svg/pointer-extend_accent.svg");
              }
            }
          }
        }
        .home_overlay, .contacts_overlay, .about_overlay{
          &:hover {
            opacity: 0.8;
          }

          &:active {
            color: $grey;
          }
        }
        .btn_overlay{
          @include font-size-15;
          font-weight: 500;
          height: 40px;
          width: 100%;
          border: 1px solid $accent;
          border-radius: 6px;
          color: $accent;



          &:active{
            color: $grey;
          }
        }
      }
    }
  }
}


.header_buymodal{
  height: 68px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
  .text_header{
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: -0.055em;

    color: #000000;
  }
}
.content_buymodal{
  overflow: hidden;
  margin: 30px;

  .product{
    .head_product{
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: -0.055em;

      /* navbar page */

      color: #152536;

    }
    .item_product{
      position: relative;
      margin-top: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      grid-template-columns: max(81px) 1fr 2fr;
      grid-column-gap: 30px;
      grid-template-rows: 1fr 1fr;
      justify-content: start;
      .photo_item{
        grid-row: 1 / -1;
        background-size: cover;
        background-position: center;

      }
      .title_item{
        grid-column: 2 / 4;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.055em;

        /* navbar page */

        color: #152536;
      }
      .coast_item{
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.055em;
        white-space: nowrap;
        color: rgba(21, 37, 54, 0.5);
      }
      .counter_item{
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.055em;
        white-space: nowrap;
        color: rgba(21, 37, 54, 0.5);
      }
    }
  }

  form{
    margin-top: 18px;
    .head_form{
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: -0.055em;

      /* navbar page */

      color: $text;
    }
    .head_description_form{
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.055em;

      /* grey invis 50% */

      color: $grey;
      max-width: 414px;
    }

    .inputs_form{
      margin-top: 18px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 13px;
      grid-column-gap: 30px;

      .item_input{
        .head_item{
          font-family: 'Montserrat', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: -0.055em;

          /* navbar page */

          color: #152536;

        }
        input{
          outline: none;
          height: 24px;
          margin-top: 10px;
          padding: 0 8px;
          width: 100%;
          border: 0;
          border-radius: 3px;
          font-family: 'Montserrat', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          color: $text;
        }
        input::placeholder{
          color: $grey;
        }
      }
      .btn_submit{
        align-self: end;
        button{
          background: #18A0FB;
          border-radius: 6px;
          border: 0;
          height: 24px;
          width: 100%;
          font-family: 'Montserrat', serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.015em;

          color: #FFFFFF;

        }
      }
      .input-privacy-div{
        grid-column: 1 / -1;
      }
    }
  }

  .smart-captcha{
    margin-top: 20px;
  }
}

.drop-down{
  position: absolute;
  background: $background;
  border: 1px solid $accent;
  border-radius: 3px;
  min-width: 100%;
  align-self: start;
  z-index: 100;
}

.form-checkboxes{
  display: flex;
  flex-direction: column;
  gap: 5px;
}