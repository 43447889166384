@font-face {
  font-family: "Alef";
  src: url("../fonts/Alef/Alef-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Alef";
  src: url("../fonts/Alef/Alef-Bold.ttf") format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype supports variations'),
  url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 100 900;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Medium.ttf") format('truetype');
  font-weight: 500;
}