$accent: #18A0FB;
$accent_darker:#18A0FB;
$text: #152536;
$background: #FFFFFF;
$darkgrey: #D9D9D9;
$grey: #7E7E7E;
$lightgrey: #EAEAEA;
$darker_white: #F5F5F5;
$black: #000000;
$available: #11D100;

$gutter: 30px;
$row_gutter: 14px;
$header_height: 80px;
$footer_height: 162px;